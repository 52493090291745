<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: yangzebin
 * @LastEditTime: 2024-04-16 17:55:53
 * @FilePath: \yda_web_manage\src\views\approval\components\notApprovalTable.vue
 * @Description: 待审批列表3.0
 * 工作台页面和待审批页面
-->
<template>
  <template v-if="pageLoading">
    <div class="base-apply-list">
      <a-spin size="large" class="loading-animation" />
    </div>
  </template>
  <template v-else>
    <section v-if="!isHome" class="form-style">
      <!-- 头部 -->
      <a-form layout="inline" ref="headFormRef" :model="headFormState">
        <!-- 搜索框 -->
        <a-form-item label="流程主题:" class="form-center" name="processTopic">
          <a-input style="width: 200px;height: 32px;" v-model:value.trim="headFormState.processTopic" :maxlength="50"
            placeholder="请输入" allowClear />
        </a-form-item>
        <!-- 流程名称 -->
        <a-form-item label="流程名称:" name="processName">
          <a-input style="width: 200px;height: 32px;" v-model:value.trim="headFormState.processName" :maxlength="30"
            placeholder="请输入" allowClear />
        </a-form-item>
        <!-- 申请人 -->
        <a-form-item label="申请人:" name="processStaffName">
          <a-input style="width: 200px;height: 32px;" v-model:value.trim="headFormState.processStaffName"
            :maxlength="30" placeholder="请输入" allowClear />
        </a-form-item>
        <!-- 文件类型 -->
        <a-form-item label="流程类型:" name="processMold">
          <a-select style="width: 200px;height: 32px;" v-model:value="headFormState.processMold" allowClear
            placeholder="请选择" class="form-select">
            <a-select-option v-for="value in processMoldOptions" :key="value.code" :value="value.code">
              {{ value.desc }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="申请时间:" name="submitTime">
          <a-range-picker :disabledDate="disabledDate" separator="→" allowClear v-model:value="headFormState.submitTime"
            style="width: 240px;height: 32px;">
            <template #suffixIcon>
              <CalendarOutlined />
            </template>
          </a-range-picker>
        </a-form-item>
        <!-- 重置/查询 -->
        <a-form-item class="form-btns">
          <a-button @click="reset">重置</a-button>
          <a-button class="form-btn" @click="searchList">查询</a-button>
        </a-form-item>
      </a-form>
    </section>
    <!-- 表格数据 -->
    <section class="table-style">
      <div class="table-head">
        <span class="title">{{ '待审批（' + pagination.total + '）' }}</span>
      </div>
      <a-table :columns="notApprovalColumn" :pagination="pagination" :dataSource="dataSource"
        @change="handleTableChange" :loading="tableLoading" :sortDirections="['ascend', 'descend']" :rowKey="(record, index) => {
      return index
    }
    " ref="tableRef" :scroll="{ x: 400 }">
        <!-- 序号 -->
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <!-- 流程主题 -->
        <template #processTopic="{ text, record }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
              <template #title>
                <span>{{ text }}</span>
              </template>
              <span :class="isHome ? 'table-text-color' : ''" @click="isHome ? openModal(record) : ''"
                :style="isHome ? 'cursor: pointer' : ''">
                {{ text ?? '-' }}
              </span>
            </a-tooltip>
            <span v-else :class="isHome ? 'table-text-color' : ''" @click="isHome ? openModal(record) : ''"
              :style="isHome ? 'cursor: pointer' : ''">
              {{ text ?? '-' }}
            </span>
          </div>
        </template>
        <!-- 流程类型 -->
        <template #processMold="{ text }">
          <div class="text-box">
            <span>{{ processType[text - 1].name }}</span>
          </div>
        </template>
        <!-- 流程名称 -->
        <template #processName="{ text }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
              <template #title>
                <span>{{ text }}</span>
              </template>
              {{ text ?? '-' }}
            </a-tooltip>
            <span v-else>{{ text ?? '-' }}</span>
          </div>
        </template>
        <!-- 申请人 -->
        <template #applyStaffName="{ text, record }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length + record.applyDepartmentName.length > 13">
              <template #title>
                <span>{{ text }}-{{ record.applyDepartmentName }}</span>
              </template>
              <span v-if="text"> {{ text ?? '--' }}-{{ record.applyDepartmentName }}</span>
              <span v-else>--</span>
            </a-tooltip>
            <span v-if="text"> {{ text ?? '--' }}-{{ record.applyDepartmentName }}</span>
            <span v-else>--</span>
          </div>
        </template>
        <!-- 当前审批人 -->
        <template #examineNames="{ text }">
          <!-- <div class="text-box"> -->
          <a-tooltip placement="bottomLeft" v-if="text?.length > 15">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text ?? '-' }}
          </a-tooltip>
          <span v-else>{{ text ?? '-' }}</span>
          <!-- </div> -->
        </template>
        <!-- 操作 -->
        <template #action="{ record }">
          <div :class="[isHome ? 'operation-area-home' : 'operation-area-approval']">
            <!-- 审批 -->
            <a v-if="!isHome" @click="openModal(record)" class="table-text-color">审批</a>
            <!-- 拒绝 -->
            <a v-if="isHome" @click="confirmApproval(record, 2)" class="table-text-color">拒绝</a>
            <!-- 同意 -->
            <a style="margin-left: 16px; display: inline-block" class="table-text-color" v-if="isHome"
              @click="confirmApproval(record, 1)">同意</a>
          </div>
        </template>
      </a-table>
    </section>
    <WrapModal :tooSmall="true" :width="446" v-model:visible="rejectVisible" :modealTitle="modelTitle"
      :maskClosable="true" :confirmLoading="confirmLoading" @ok="submit" @cancel="cancel">
      <a-form ref="formRef" :model="formState">
        <a-form-item label="审批意见" name="option">
          <a-textarea v-model:value="formState.options" showCount @pressEnter="e => lineFeed(e)" :maxlength="50"
            placeholder="请输入审批意见" style="width: 320px" :autoSize="{ minRows: 3 }" />
        </a-form-item>
      </a-form>
    </WrapModal>
  </template>
</template>

<script>
import { useStore, createVNode } from 'vuex'
import { reactive, toRefs, onMounted, ref, watch, toRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { notApprovalColumn } from '../columns'
import {
  getNotApprovalList,
  getApprovedDetail,
  getElectronicApprovedDetail,
  approve,
  getAllType
} from '@/apis/approval'
import { setTablePageFooterStyle, cmsMessage } from '@/utils/utils'
import { paginationParams, searchType, processType } from '@/utils/constData'
import WrapModal from '@/components/WrapModal/index'
import { Modal, notification } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import moment from 'moment'
import { getEnums } from '@/apis/user'
const sealTypeList = {
  1: { name: '实体章' },
  2: { name: '电子章' }
}

export default {
  name: 'not-approval-table',
  components: {
    WrapModal
  },
  props: {
    isHome: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const pageLoading = ref(true)
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const formRef = ref()
    const headFormRef = ref()
    const $store = useStore()
    const headFormState = reactive({
      processTopic: undefined, //流程主题
      processStaffName: undefined, //申请人
      processMold: undefined, //流程类型
      processName: undefined, //流程名称
      submitTime: [] //申请时间
    })
    const state = reactive({
      applyStatus: undefined,
      sealStatus: undefined,
      documentId: undefined,
      timeSort: '',
      pagination: {
        ...paginationParams
      },
      processType: processType,
      dataSource: [],
      detail: undefined,
      tableLoading: true,
      modelTitle: '拒绝原因',
      rejectVisible: false,
      confirmLoading: false,
      formState: {
        options: ''
      },
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).enterpriseId,
      currentNum: 1,
      processMoldOptions: [] //流程类型搜索选项
    })
    const data = reactive({
      documentId: undefined, //文件id
      opinion: '', //审批意见
      params: {}, //其他参数
      staffId: undefined, //审批人id
      taskId: route.query.taskId, //审批任务id
      state: undefined, //当前审批人的状态 3同意、4拒绝
      dataId: undefined
    })
    // 获取列表
    const getList = async () => {
      state.tableLoading = true
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        processTopic: headFormState.processTopic, //模糊查询内容
        processStaffName: headFormState.processStaffName, //搜索项类型
        processMold: headFormState.processMold, //流程类型
        processName: headFormState.processName,
        startTime: headFormState.submitTime[0] ? moment(headFormState.submitTime[0]).format('YYYY-MM-DD 00:00:00') : '', //开始时间
        endTime: headFormState.submitTime[1] ? moment(headFormState.submitTime[1]).format('YYYY-MM-DD 00:00:00') : '' //结束时间
      }
      const res = await getNotApprovalList(params)
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
      state.documentId = undefined
    }
    //搜索
    const searchList = () => {
      // 搜索数据
      state.pagination.current = 1
      getList()
    }
    //重置
    const reset = () => {
      headFormRef.value.resetFields()
      headFormState.searchType = 1
      state.pagination.current = 1
      getList()
    }
    //流程类型
    const getprocessMold = value => {
      const textMap = state.processMoldOptions
      if (textMap) {
        return textMap[value - 1].desc
      }
    }
    // 日期选择器：当天之后不可选
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    }

    const handleTableChange = ({ current, pageSize }, filters, { order }) => {
      // 排序
      state.timeSort = ''
      if (order == 'ascend') {
        state.timeSort = 1
      }
      state.dataSource = []
      // 页码change
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    // 文本框回车禁止
    const lineFeed = e => {
      e.preventDefault()
    }
    // 点击审批按钮事件
    const openModal = async current => {
      console.log('current', current)
      router.push({
        path: '/approval/notApproval/pendingdetail',
        query: {
          type: 0,
          id: current.id,
          documentId: current.documentId,
          processInstanceId: current.processInstanceId,
          taskId: current.taskId,
          hasCover: current.hasCover,
          dataId: current.dataId,
          processMold: current.processMold
        }
      })
    }
    //审批接口
    const getApproveResult = params => {
      console.log('审批params', params)
      approve(params)
        .then(res => {
          if (res.success) {
            setTimeout(() => {
              state.confirmLoading = false
            }, 500)
            cancel()
            cmsMessage('success', '审批成功')
            getList()
          } else {
            if (res.code === 8011) {
              //当前用户已审批
              notification.destroy()
              Modal.confirm({
                centered: true,
                title: `本审批节点已通过，请勿重复操作`,
                icon: createVNode(ExclamationOutlined),
                okText: '确定'
              })
            } else if (res.code === 8012) {
              //当前用户已审批
              notification.destroy()
              Modal.confirm({
                centered: true,
                title: `本审批节点已拒绝，请勿重复操作`,
                icon: createVNode(ExclamationOutlined),
                okText: '确定'
              })
            }
            setTimeout(() => {
              state.confirmLoading = false
            }, 500)
            cancel()
            getList()
          }
        })
        .catch(err => {
          console.log(err)
          setTimeout(() => {
            state.confirmLoading = false
          }, 500)
          cancel()
          getList()
        })
    }

    const confirmApproval = (current, status) => {
      data.documentId = current.documentId
      data.params = current.params
      data.taskId = current.taskId
      data.state = status
      data.dataId = current.dataId
      data.processMold = current.processMold
      if (status == 2) {
        state.rejectVisible = true
        return
      }
      getApproveResult(toRaw(data))
    }

    // 拒绝弹窗
    const submit = () => {
      state.confirmLoading = true
      data.opinion = state.formState.options?.trim()
      if (!data.opinion) {
        cmsMessage('error', '请输入审批意见')
        state.confirmLoading = false
        return
      }
      getApproveResult(toRaw(data))
    }
    const cancel = () => {
      state.rejectVisible = false
      state.formState.options = ''
    }

    // 第三方跳转
    const thirdparty = async () => {
      const documentId = route.query.documentId
      const flag = route.query.flag
      const sealId = route.query.sealId
      const sealType = route.query.sealType
      if (documentId) {
        openModal({
          status: sealType == 2 ? 3 : 2,
          documentId: documentId,
          flag: flag,
          sealId: sealId,
          sealType: sealType === '1' ? 1 : 2
        })
        state.documentId = documentId
      }
    }
    const getprocessMoldOptions = () => {
      let res = getEnums({ kind: 'PROCESS_MOLD' }).then(res => {
        if (res.success) {
          // console.log('666666666666',res.data);
          state.processMoldOptions = res.data
        }
      })
    }
    onMounted(async () => {
      data.staffId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.staffId //当前登录的用户id
      console.log('页面挂载时当前登录用户的id', data.staffId)
      const documentId = route.query.documentId
      const flag = route.query.flag
      const sealId = route.query.sealId
      const sealType = route.query.sealType
      await getprocessMoldOptions()
      if (documentId) {
        let res
        if (sealType == 1) {
          res = await getApprovedDetail({
            status: 2,
            documentId: documentId,
            flag: flag,
            sealId: sealId
          })
        } else if (sealType == 2) {
          res = await getElectronicApprovedDetail({
            documentId: documentId
          })
        }
        if (res.data?.examineStatus) {
          router.push({
            path: '/approval/approved',
            query: {
              documentId: documentId,
              flag: flag,
              sealId: sealId,
              sealType: sealType
            }
          })
          return
        }
        state.documentId = documentId
      }
      await getList()
      pageLoading.value = false

      setTimeout(() => {
        thirdparty()
      }, 500)
    })
    watch(
      () => $store.state.user.userInfo.enterpriseId,
      () => {
        console.log('切换企业触发')
        data.staffId = JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.staffId //当前登录的用户id
        console.log('切换企业后当前登录用户的id', data.staffId)
        getList()
      }
    )

    return {
      ...toRefs(state),
      getList,
      openModal,
      searchList,
      handleTableChange,
      confirmApproval,
      tableRef,
      lineFeed,
      cancel,
      submit,
      reset,
      getprocessMold,
      headFormRef,
      headFormState,
      sealTypeList,
      searchType,
      notApprovalColumn,
      getApproveResult,
      data,
      formRef,
      pageLoading,
      disabledDate
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-area-home {
  width: 92px;
}

.operation-area-approval {
  width: 36px;
}

.base-apply-list {
  min-height: calc(100vh - 180px);
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seal-list {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

:deep(.ant-input-textarea-show-count::after) {
  position: relative;
  top: -35px;
  right: 5px;
  height: 0;
}

.text-box {
  max-width: 13em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
